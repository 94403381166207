<template>
  <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
    <div class="uk-width-1-1">
      <h1 class="uk-heading-line">
        {{ headerTitle }} 
      </h1>
    </div>
    <create
      :images="images"
      :list-farmer="listFarmer"
      :set-toggle-modal-add-edit-discard="setToggleModalAddEditDiscard"
      :get-toggle-modal-add-edit-discard="getToggleModalAddEditDiscard"
      :set-data-modal-add-edit="setDataModalAddEdit"
    />
    <modal-add-edit-discard 
      :set-toggle-modal-add-edit-discard="setToggleModalAddEditDiscard"
      :get-toggle-modal-add-edit-discard="getToggleModalAddEditDiscard"
      :handle-modal-button="handleModalSave"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { PREFIX_IMAGE } from '@/utils/constant'
import Create from './Create'
import getHeaderTitle from '@/utils/header-title'
import { notificationSuccess } from '@/utils/notification'
import ModalAddEditDiscard from '@/components/globals/modals/ModalAddEditDiscard'

export default {
  components: {
    Create,
    ModalAddEditDiscard
  },
  data() {
    return {
      images: PREFIX_IMAGE
    }
  },
  computed: {
    ...mapGetters({
      listFarmer: 'purchaseOrder/list_farmer',
      getDataModalAddEdit: 'purchaseOrder/get_data_modal_add_edit',
      getToggleModalAddEditDiscard: 'purchaseOrder/get_toggle_modal_add_edit_discard'
    }),
    headerTitle() {
      return getHeaderTitle(this.$route.fullPath)
    }
  },
  async mounted() {
    await this.getFarmerList()
  },
  methods: {
    ...mapMutations({
      setDataModalAddEdit: 'purchaseOrder/SET_DATA_MODAL_ADD_EDIT',
      setToggleModalAddEditDiscard: 'purchaseOrder/SET_TOGGLE_MODAL_ADD_EDIT_DISCARD'
    }),
    ...mapActions({
      getFarmerList: 'purchaseOrder/getListFarmerList',
      createPurchase: 'purchaseOrder/createPoFarmer'
    }),
    handleModalSave() {
      this.setToggleModalAddEditDiscard({
        ...this.getToggleModalAddEditDiscard,
        type: 'save',
        loadingTable: false,
        loadingPopUp: true
      })
      this.createPurchase(this.getDataModalAddEdit).then(result => {
        if (result.message == 'OK') {
          setTimeout(() => {
            this.setToggleModalAddEditDiscard({
              ...this.getToggleModalAddEditDiscard,
              loadingTable: false,
              loadingPopUp: false
            })
            notificationSuccess('Add PurchaseOrder Successful')
          }, 500)
          setTimeout(() => {
            this.$router.push({ name: 'PurchaseOrderFarmer' })
            this.setToggleModalAddEditDiscard({
              targetModals: false,  
              title: '',
              description: '',
              type: '',
              customTitleBtnOk: '',
              customTitleBtnCancel: '',
              customClass: '',
              loadingTable: false,
              loadingPopUp: false
            })
          }, 1000)
        }
      })
    }
  }
}
</script>
