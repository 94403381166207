<template>
  <div>
    <div class="uk-card uk-card-default uk-margin-top base-card">
      <div class="uk-form-stacked">
        <div
          class="uk-child-width-expand@s uk-grid-medium"
          uk-grid
        >
          <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l">
            <div
              class="uk-child-width-expand@s uk-grid-medium"
              uk-grid
            >
              <div class="uk-inline uk-width-1-1">
                <label class="uk-form-label">
                  Farmer Type
                </label>
                <div class="uk-form-controls">
                  <multiselect
                    v-model="farmerTypeChoose"
                    v-validate="'required'" 
                    label="name" 
                    name="farmerTypeChoose" 
                    track-by="id" 
                    placeholder="Select..." 
                    open-direction="bottom" 
                    :options="listFarmerType||[]"
                    :searchable="true"
                    :max-height="200"
                    :show-no-results="true"
                  >
                    <span slot="noResult">Oops! Data tidak ditemukan.</span>
                  </multiselect>
                </div>
              </div>

              <div class="uk-inline uk-width-1-1">
                <label class="uk-form-label">
                  PO Date
                </label>
                <div class="uk-inline uk-width-1-1">
                  <img
                    :src="`${images}/icon/ic_calendar.svg`"
                    alt=""
                    class="uk-form-icon uk-form-icon-flip"
                  >
                  <datepicker
                    ref="purchase_order_date"
                    v-model="purchase_order_date"
                    v-validate="'required'"
                    format="DD/MM/YYYY"
                    name="purchase_order_date"
                    input-class="uk-input"
                    placeholder="DD/MM/YYYY"
                  />
                </div>
                <span
                  v-show="errors.has('purchase_order_date')"
                  class="uk-text-small uk-text-danger"
                >{{ errors.first('purchase_order_date') }}</span>
              </div>

              <div class="uk-inline uk-width-1-1">
                <label class="uk-form-label">
                  Total Weight
                </label>
                <div class="uk-form-controls">
                  <div class="uk-inline uk-width-1-1">
                    <span class="uk-form-icon uk-form-icon-flip">Kg</span>
                    <input
                      v-model="total_weight"
                      v-validate="'required|max:9'"
                      name="total_weight"
                      class="uk-input"
                      type="text"
                      placeholder="0"
                      :class="{'uk-form-danger': errors.has('total_weight')}"
                      disabled
                      @change="formatWeight"
                    >
                  </div>
                </div>
                <span
                  v-show="errors.has('total_weight')"
                  class="uk-text-small uk-text-danger"
                >{{ errors.first('total_weight') }}</span>
              </div>

              <div class="uk-inline uk-width-1-1">
                <label class="uk-form-label">
                  Facility Code
                </label>
                <div class="uk-form-controls">
                  <input
                    v-model="facility.vcf_code"
                    class="uk-input"
                    type="text"
                    placeholder="EVC-XXX"
                    disabled
                  >
                </div>
              </div>
            </div>
          </div>
          
          <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l">
            <div
              class="uk-child-width-expand@s uk-grid-medium"
              uk-grid
            >
              <div class="uk-inline uk-width-1-1">
                <label class="uk-form-label">
                  Farmer Name
                </label>
                <div class="uk-form-controls">
                  <multiselect
                    v-model="farmerChoose"
                    v-validate="'required'" 
                    label="facility_name" 
                    name="farmerChoose" 
                    track-by="id" 
                    placeholder="Select..." 
                    open-direction="bottom" 
                    :options="listFarmer||[]"
                    :searchable="true"
                    :max-height="200"
                    :show-no-results="true"
                  >
                    <span slot="noResult">Oops! Data tidak ditemukan.</span>
                  </multiselect>
                </div>
              </div>

              <div class="uk-inline uk-width-1-1">
                <label class="uk-form-label">
                  Receive PO Date
                </label>
                <div class="uk-inline uk-width-1-1">
                  <img
                    :src="`${images}/icon/ic_calendar.svg`"
                    alt=""
                    class="uk-form-icon uk-form-icon-flip"
                  >
                  <datepicker
                    ref="purchase_order_received_date"
                    v-model="purchase_order_received_date"
                    v-validate="'required'"
                    format="DD/MM/YYYY"
                    name="purchase_order_received_date"
                    input-class="uk-input"
                    placeholder="DD/MM/YYYY"
                  />
                </div>
                <span
                  v-show="errors.has('purchase_order_received_date')"
                  class="uk-text-small uk-text-danger"
                >{{ errors.first('purchase_order_received_date') }}</span>
              </div>
              
              <div class="uk-inline uk-width-1-1">
                <label class="uk-form-label">
                  Facility Type
                </label>
                <div class="uk-form-controls">
                  <input
                    :value="facility_type?.name == 'Hulling Facility' ? 'Huller' : 'Pulper' "
                    class="uk-input"
                    type="text"
                    placeholder="Facility Type"
                    disabled
                  >
                </div>
              </div>
              
              <div class="uk-inline uk-width-1-1">
                <label class="uk-form-label">
                  Species
                </label>
                <div class="uk-form-controls">
                  <input
                    v-model="species.name"
                    v-validate="'required|max:16'"
                    class="uk-input"
                    type="text"
                    placeholder="Species"
                    disabled
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="uk-card uk-card-default uk-margin-top base-card">
      <h4 class="uk-heading-line">
        Produk Type
      </h4>
      <div class="uk-form-stacked form-dry-fields">
        <div
          v-for="(data, index) in products"
          :key="index"
          class="uk-child-width-expand@s uk-grid-medium" 
          uk-grid
        >
          <div class="uk-width-1-4">
            <div class="uk-form-controls uk-position-relative withlabel">
              <label class="uk-form-label">
                Varietas
              </label>
              <select
                v-model="data.variety_id"
                class="uk-width-expand custom-select"
                name="variety_id"
              >
                <option
                  value=""
                  hidden
                >
                  Select Varietas
                </option>
                <option
                  v-for="(item, key) in listVarietas"
                  :key="key"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
              <img
                :src="`${images}/icon/ic_chevron_down.svg`"
                alt="ic_chevron"
              >
            </div>
            <span
              v-show="errors.has(`variety_id${index + 1}`)"
              class="uk-text-small uk-text-danger"
            >{{ errors.first(`variety_id${index + 1}`) }}</span>
          </div>

          <div class="uk-width-1-4">
            <label class="uk-form-label">
              Berat
            </label>
            <div class="uk-form-controls">
              <div class="uk-inline uk-width-1-1">
                <span class="uk-form-icon uk-form-icon-flip">Kg</span>
                <input
                  v-model="data.weight"
                  :name="`weight_${index + 1}`"
                  class="uk-input"
                  type="number"
                  autocomplete="off"
                  placeholder="0"
                  :class="{'uk-form-danger': errors.has(`weight_${index + 1}`)}"
                  @input="handleTotalWeight()"
                >
              </div>
              <span
                v-show="errors.has(`weight_${index + 1}`)"
                class="uk-text-small uk-text-danger"
              >{{ errors.first(`weight_${index + 1}`) }}</span>
            </div>
          </div>

          <div class="uk-width-1-4">
            <label class="uk-form-label">
              Berat Penerimaan
            </label>
            <div class="uk-form-controls">
              <div class="uk-inline uk-width-1-1">
                <span class="uk-form-icon uk-form-icon-flip">Kg</span>
                <input
                  v-model="data.received_weight"
                  :name="`received_weight_${index + 1}`"
                  class="uk-input"
                  type="number"
                  autocomplete="off"
                  placeholder="0"
                  :class="{'uk-form-danger': errors.has(`received_weight_${index + 1}`)}"
                >
              </div>
              <span
                v-show="errors.has(`received_weight_${index + 1}`)"
                class="uk-text-small uk-text-danger"
              >{{ errors.first(`received_weight_${index + 1}`) }}</span>
            </div>
          </div>

          <div class="uk-width-1-4">
            <div
              class="img-fields"
            >
              <img
                :src="`${images}/icon/ic_delete_table.svg`"
                alt=""
                @click.prevent="handleDeleteFormProductType(index + 1)"
              >
            </div>
          </div>
        </div>
      </div>
      
      <div
        class="uk-child-width-expand@s uk-grid-small uk-margin-small-top"
        uk-grid
      >
        <button
          class="btn-add-create-form uk-button uk-flex uk-align-center uk-width-expand uk-width-auto@s"
          style="justify-content: flex-start;"
          @click.prevent="handleCreateFormProductType()"
        >
          <img
            :src="`${images}/icon/ic_plus_green.svg`"
            class="uk-margin-small-right cursor-pointer"
          >
          Add Product Type
        </button>
      </div>
    </div>
    <div class="uk-flex uk-flex-right uk-margin-medium-top">
      <button
        class="uk-button soft-green uk-margin-right"
        @click="showCancelConfirmModal"
      >
        Cancel
      </button>
      <button
        class="uk-button green"
        @click="showSaveConfirmModal"
      >
        Save
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Datepicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { STATUS } from '@/utils/constant'
import { dateString } from '@/utils/formater'
import { isCanAccess } from '@/utils/auth'

export default {
  components: {
    Datepicker
  },
  props: {
    images: {
      required: true,
      type: String
    },
    listFarmer: {
      required: true,
      type: Array
    },
    setToggleModalAddEditDiscard: {
      required: true,
      type: Function
    },
    getToggleModalAddEditDiscard: {
      required: true,
      type: Object
    },
    setDataModalAddEdit: {
      required: true,
      type: Function
    }
  },
  
  data() {
    return{
      status: STATUS,
      formPurchase: {},
      purchase_order_number: '',
      farmerTypeChoose: '',
      farmerChoose: '',
      species: {},
      varieties: {},
      facility: {},
      total_weight: '',
      purchase_order_date: new Date(),
      purchase_order_received_date: new Date(),
      products: [
      ]
    }
  },
  computed:{
    ...mapGetters({
      listFarmerType: 'masterData/list_master_data',
      listVarietas: 'masterData/list_varieties_type'
    })
  },
  watch:{
    farmerChoose(){
      this.handleSelectFarmer(this.farmerChoose)
    }
  },
  async mounted(){
    await this.actionGetListFarmerType({ data_type: 'farmer_type'})
    await this.actionGetListVarietiesType({ data_type: 'varieties', limit: 1000})
  },
  methods: {
    handleTotalWeight(){
      this.total_weight = parseInt(this.products.reduce((accumulator, currentValue) => accumulator + parseInt(currentValue.weight), 0))
    },
    handleCreateFormProductType() {
      this.products.push({
        variety_id: '',
        weight: null,
        received_weight: null
      })
    },
    handleDeleteFormProductType(index) {
      this.products.splice(index - 1, 1)
    },
    ...mapActions({
      actionGetListFarmerType: 'masterData/getListMasterData',
      actionGetListVarietiesType: 'masterData/getListVarietiesType',
      actionGetListHulling: 'hullingFacility/getListHulling',
      actionGetListPulping: 'pulpingFacility/getListPulping',
      actionGetListPlantation: 'plantation/getListPlantation'
    }),
    isCanAccessUser(type, menu) {
      return isCanAccess(type, menu)
    },
    formatWeight(){
      this.weight = this.weight + ' Kg' 
    },
    handleSelectFarmer(fc){

      this.varieties = {
        id: fc.varieties.id,
        name: fc.varieties.name
      }

      this.species = {
        id: fc.species.id,
        name: fc.species.name
      }

      this.facility = {
        id: fc.facility_id,
        name: fc.facility_name,
        vcf_code: fc.vcf_code
      }

      this.facility_type = {
        id: fc.facility_type.id,
        name: fc.facility_type.name
      }

    },
    showCancelConfirmModal() {
      this.setToggleModalAddEditDiscard({
        targetModals: true,
        title: 'Cancel Confirmation', 
        description: 'All data will be lost if you leave. Are you sure want to leave?',
        customTitleBtnOk: 'Yes',
        customTitleBtnCancel: 'No',
        type: 'discard',
        route: 'PurchaseOrderFarmer',
        loadingTable: false,
        loadingPopUp: false
      })
    },
    showSaveConfirmModal() {

      const convertToFloat64 = obj => {
        for (const key in obj) {
          if (typeof obj[key] === "object" && obj[key] !== null) {
            convertToFloat64(obj[key]) // Recursive call for nested objects
          } else if (key === "weight" || key === "received_weight") {
            obj[key] = parseFloat(obj[key]) // Convert string to float64
          }
        }
        return obj
      }

      this.formPurchase = {
        farmer_type_id: this.farmerTypeChoose.id,
        farmer_id: this.farmerChoose.id,
        purchase_order_date: dateString(this.purchase_order_date),
        purchase_order_received_date: dateString(this.purchase_order_received_date),
        facility_type_id: this.facility_type.id,
        facility_id: this.facility.id,
        species_id: this.species.id,
        products: this.products.map(obj => convertToFloat64({ ...obj }))
      }
      this.$validator.validateAll().then((success) => {
        if (success) {
          if (this.$validator.errors.any()) return
          this.setDataModalAddEdit(this.formPurchase)
          this.setToggleModalAddEditDiscard({
            targetModals: true,
            title: 'Save Confirmation', 
            description: 'Are you sure want to save this data?',
            customTitleBtnOk: 'Save',
            customTitleBtnCancel: 'Cancel',
            type: 'save',
            loadingTable: false,
            loadingPopUp: false
          })
        }
      })
    }
  }
}
</script>

<style scoped>
   .custom-select.disabled{
    background: #DEDEDE;
  }
  .form-facility-fields .uk-width-1-3 {
    max-width: 32%;
  }
  .btn-add-create-form {
    font-family: 'interMedium';
    font-weight: 600;
    font-size: 16px;
    background: transparent;
    color: #2FAC4A;
  }
  .img-fields {
    display: flex;
    justify-items: center;
    align-items: center;
    margin-top: 30px;
    cursor: pointer;
  }
  .ic-delete-form{
    width: 24px;
    height: 24px;
    max-width: 24px !important;
  }
  .uk-input:disabled{
    background: #F4F4F4 !important;
    color: #000000 !important;
  }
  .uk-form-icon {
    width: 24px;
    height: 24px;
    top: 25%;
    z-index: 1;
  }
  .uk-form-icon-flip {
    right: 10px;
    left: auto;
  }
</style>
